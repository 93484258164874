import React from 'react';
import Layout from '../components/Layout'
import { Container, Row, Col } from 'reactstrap';
import Banner from '../images/Faq-banner.jpg'
import Story from '../images/About/banner3.jpeg'
import "firebase/auth";


function Faq() {
    return (
        <Layout>
            <section className="story_banner">
                <Container>
                    <Row>
                        <Col md="12">
                <img src={Banner} alt="img" />
                </Col>
                </Row>
                </Container>
            </section>
            <section className="story_section">
                <Container>
                    <Row>
                        <Col md="12">
                           <div>
                               <span>1.	Which City Are You Located In?</span>
                               <p>We are located in Jaipur City of Rajasthan Province.</p>
                               <span>2.	Is This Your Family Oriented Business?</span>
                               <p>Yes, this is a family oriented business.</p>
                               <span>3.	Are You Able To Provide The Wood Traceability And The Legality Of Wood?</span>
                               <p>Yes, we have a proper traceability system and we only use wood from legal sources. We can provide you with all the documentation to prove the legality of wood and traceability.</p>
                               <span>4.	Do You Make Everything In The Factory or You Outsource From Other Factories?</span>
                               <p>We make 90% of things in our factory and there are only few processes which are done outside our factory</p>
                               <span>5.	What Kind of Wood You Mostly Work With?</span>
                               <p>We mostly work with Acacia, Teak, Sheesham and mango. We also work with wood like Pine and Oak.</p>
                               <span>6.	What Are Your Minimum Order Quantities (MOQ)?</span>
                               <p>Our MOQ is a 20 feet container with variety of Items but we prefer minimum 10 pieces in each style.</p>
                               <span>7.	What Is Your Lead Time?</span>
                               <p>We usually take 90 days for production of every order but we are planning to reduce our lead time.</p>
                               <span>8. What Are Your Pricing Terms?</span>
                               <p>We mostly worked with FOB prices.</p>
                               <span>9.	What Are Your Payment Terms?</span>
                               <p>We work with 30% advance and balance 70% against draft bill of lading and invoice.</p>
                               <span>10. Which Shipping Port Do You Use In India?</span>
                               <p>We most of the time use Mundra Port which is around 900 km from our factory.</p>
                               <span>11. Do you produce custom made and custom designed furniture?</span>
                               <p>Yes, we do on a case-to-case basis. Please contact us at info@greyspacefurniture.com</p>
                               <span>12. Is my furniture made of solid wood?</span>
                               <p>While many of our products are constructed entirely of solid hardwood, some items may only include solid hardwood construction in high-impact areas such as the foundation or frame. In addition to a hardwood foundation, the product may also include MDF of furniture-grade plywood in low-impact or decorative areas where strength isn't necessary. The reason for this combination is to save on the cost of materials in order to guarantee more affordable prices alongside high-quality materials and additional support.</p>
                               <spn>13.	How Do I Request A Quotation?</spn>
                               <p>Please send us an email with your company name, address, and contact numbers. Please include the items you’d like us to quote for you including the quantity needed and delivery address.</p>
                               <span>14. Do You Offer Private Labelling?</span>
                               <p>Yes, we do with certain MOQ (Minimum Order Quantity).</p>
                           </div>
                        </Col>
                        
                    </Row>
                </Container>
            </section>
        </Layout>
    );
}

export default Faq;
